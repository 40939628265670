import { combineReducers } from "redux";
import {
  RECEIVE_LPS_CONFIGS_SUCCESS,
  RECEIVE_LPS_CONFIGS_ERROR,
  CREATE_EDIT_LPS_CONFIG_ERROR,
  DELETE_LPS_CONFIG_ERROR,
  RUN_PROCESSING_LPS_REQUEST,
  FINISH_PROCESSING_LPS_SUCCESS,
  FINISH_PROCESSING_LPS_ERROR,
  SET_LPS_LEAD_INDEX,
  RECALCULATE_DEFAULT_PROFILE,
  RESET_CURRENT_CALCULATION_SUCCESS,
} from "../../../common/constants/action-constants";
import UserService from "../../../common/services/user.service";

const initialState = {
  isFetching: false,
  // @ts-ignore
  result: JSON.parse(UserService.LPC),
  indexLPSItem: -1,
  isCalculated: false,
};
console.log();
const receiveLPSConfigsList = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_LPS_CONFIGS_SUCCESS:
      return action.lpsConfigs;
    default:
      return state;
  }
};

const receiveCalculationProcessing = (state = initialState, action) => {
  switch (action.type) {
    case RUN_PROCESSING_LPS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FINISH_PROCESSING_LPS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        indexLPSItem: -1,
        result: action.result,
      };
    case FINISH_PROCESSING_LPS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    // case EDIT_LPS_CONFIGS_SUCCESS:
    //   return {
    //     ...state,
    //     updatedConfig: action.updatedConfig,
    //   };
    //
    // case CHECK_BEST_LEAD_REQUEST:
    //   return {
    //     ...state,
    //     isFetching: true,
    //   };
    //
    // case CHECK_BEST_LEAD_SUCCESS:
    //   return {
    //     ...state,
    //     isFetching: false,
    //   };
    //
    case SET_LPS_LEAD_INDEX:
      return {
        ...state,
        indexLPSItem: action.indexLPSItem,
      };
    //
    // case NEXT_LPS_ITEM:
    //   return {
    //     ...state,
    //   };
    //
    // case PREV_LPS_ITEM:
    //   return {
    //     ...state,
    //   };
    //
    // case SHOW_ASSIGNED:
    //   return {
    //     ...state,
    //     assigned: action.assigned,
    //   };
    //
    // case SHOW_SCHEDULED:
    //   return {
    //     ...state,
    //     scheduled: action.scheduled,
    //   };
    //
    // case SKIP_UNASSIGNED_LEADS_IN_LPS_RATING:
    //   return {
    //     ...state,
    //     isSkipped: action.isSkipped,
    //   };
    //
    case RECALCULATE_DEFAULT_PROFILE:
      return {
        ...state,
        isCalculated: action.isCalculated,
      };
    //
    // case SET_CURRENT_USER_ID:
    //   return {
    //     ...state,
    //     selectedUserIds: action.selectedUserIds,
    //   };
    //
    // case FIND_LEAD_FLAG:
    //   return {
    //     ...state,
    //     findLeadFlag: action.findLeadFlag,
    //   };

    default:
      return state;
  }
};

const resetCurrentCalculation = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CURRENT_CALCULATION_SUCCESS:
      return action.data;
    default:
      return state;
  }
};

const errorLPSConfigsList = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_LPS_CONFIGS_ERROR:
    case CREATE_EDIT_LPS_CONFIG_ERROR:
    case DELETE_LPS_CONFIG_ERROR:
      return action.error;
    default:
      return state;
  }
};

const lps = combineReducers({
  resetCurrentCalculation,
  processing: receiveCalculationProcessing,
  lpsConfigs: receiveLPSConfigsList,
  error: errorLPSConfigsList,
});

export default lps;
