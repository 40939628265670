import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import styles from './ObjectEditor.styles';
import ObjectGeneralFieldsBlock from './object-editor-blocks/ObjectGeneralFieldsBlock';
import ObjectPlotBlock from './object-editor-blocks/ObjectPlotBlock';
import ObjectAddressBlock from './object-editor-blocks/ObjectAddressBlock';
import ObjectUnitsBlock from './object-editor-blocks/ObjectUnitsBlock';
import { kadIdValidator, objectFormValidator } from '../helpers/validator';
import AlertService from '../../services/notification.service';
import { createNewObjectRequest, updateObjectRequest } from '../../../modules/add-new-entity/actions';
import { connect, useDispatch } from 'react-redux';
import { selectLeadgen } from '../../../modules/leadgen/selectors';

const initialErrorsState = {
  isFilledKadId: true,
  isFilledCityCode: true,
  isFilledPlotNumber: true,
  isFilledSection: true,
  isFilledPlotIndex: true,
  isFilledCity: true
};
const ObjectEditor = (props) => {
  // @ts-ignore
  const { typeEdit, currentObject, objectId } = props;
  const { plot } = currentObject;
  console.log('currentObject', currentObject);
  const initialObjectState = {
    general_fields: {
      kadaster_id: '',
      kadaster_url: '',
      price: '',
      restrictions: ''
    },
    plot: {
      city_code: '',
      section: '',
      plot_number: '',
      index: ''
    },
    address: {
      city_id: '',
      city_name: '',
      street_name: '',
      number: '',
      number_add: '',
      postcode: ''
    },
    units: []
  };
  const [objectForSave, setObjectForSave] = useState(initialObjectState);
  const [errors, setErrors] = useState(initialErrorsState);
  const dispatch = useDispatch();


  useEffect(() => {
    if (typeEdit === 'objectEdit') {
      setObjectForSave({
        ...objectForSave,
        general_fields: {
          ...objectForSave.general_fields,
          kadaster_id: currentObject.kadaster_id || '',
          kadaster_url: currentObject.kadaster_url || '',
          price: currentObject.price || '',
          restrictions: currentObject.restrictions || ''
        },
        plot: {
          ...objectForSave.plot,
          city_code: plot.city_code || '',
          section: plot.section || '',
          plot_number: plot.plot_number || '',
          index: plot.index || ''
        },
        address: {
          ...objectForSave.address,
          city_id: currentObject.cityId || '',
          city_name: currentObject.city || '',
          street_name: currentObject.object_street_name || '',
          number: currentObject.object_house_number || '',
          number_add: currentObject.number_add || '',
          postcode: ''
        },
        units: currentObject.units || []

      });
      // setObjectId(currentObject.object_id)
    }
  }, [typeEdit, currentObject, plot]);

  const handleChange = (
    name,
    key,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (name === 'generalFields') {
      if (objectForSave.general_fields.kadaster_id) {
        setErrors({ ...errors, isFilledKadId: true });
      }
      return setObjectForSave({
        ...objectForSave,
        general_fields: {
          ...objectForSave.general_fields,
          [key]: event.target.value
        }
      });
    }
    if (name === 'objectPlot') {
      if (objectForSave.plot.city_code) {
        setErrors({ ...errors, isFilledCityCode: true });
      }
      if (objectForSave.plot.plot_number) {
        setErrors({ ...errors, isFilledPlotNumber: true });
      }
      if (objectForSave.plot.index) {
        setErrors({ ...errors, isFilledPlotIndex: true });
      }
      if (objectForSave.plot.section) {
        setErrors({ ...errors, isFilledSection: true });
      }

      return setObjectForSave({
        ...objectForSave,
        plot: {
          ...objectForSave.plot,
          [key]: event.target.value
        }
      });
    }
    if (name === 'objectAddress') {
      if (objectForSave.address.city_name !== '') {
        setErrors({ ...errors, isFilledCity: true });
      }

      return setObjectForSave({
        ...objectForSave,
        address: {
          ...objectForSave.address,
          [key]: event.target.value
        }
      });
    }
  };

  const updateObject = (name, item, editMode) => {
    if (name === 'objectUnits') {
      if (editMode) {
        return setObjectForSave({
          ...objectForSave,
          // @ts-ignore
          units: [...item.filteredList]
        });
      }
      return setObjectForSave({
        ...objectForSave,
        // @ts-ignore
        units: [...item]
      });
    }
  };

  const handleSave = async () => {
    let isExistedKadId;
    if (objectForSave.general_fields.kadaster_id) {
      isExistedKadId = await kadIdValidator('object', objectForSave);

      if (isExistedKadId) {
        return AlertService.warning('Object with this KAD ID already exits');
      }
    }

    const isValidObject = objectFormValidator(objectForSave);
    setErrors(isValidObject);
    if (
      Object.keys(isValidObject).every((item) => isValidObject[item] === true)
    ) {
      if (typeEdit !== 'objectEdit') {dispatch(createNewObjectRequest(objectForSave)) }
      if (typeEdit === 'objectEdit') {
        dispatch(updateObjectRequest(objectId, objectForSave));
        // console.log('Object edit updated successfully');
        props.closeModal();
      }
    } else {
      AlertService.error('Fields are not valid');
    }
  };

  const handleCancel = () => {
    setErrors(initialErrorsState);
    setObjectForSave({
      general_fields: {
        kadaster_id: '',
        kadaster_url: '',
        price: '',
        restrictions: ''
      },
      plot: {
        city_code: '',
        section: '',
        plot_number: '',
        index: ''
      },
      address: {
        city_id: '',
        city_name: '',
        street_name: '',
        number: '',
        number_add: '',
        postcode: ''
      },
      units: []
    });
  };

  return (
    <Box sx={styles.root}>
      <ObjectGeneralFieldsBlock
        onChange={handleChange}
        objectFields={objectForSave.general_fields}
        errors={errors}
      />
      <ObjectPlotBlock
        onChange={handleChange}
        objectFields={objectForSave.plot}
        errors={errors}
      />
      <ObjectAddressBlock
        onChange={handleChange}
        objectFields={objectForSave.address}
        errors={errors}
      />
      <ObjectUnitsBlock onChange={updateObject} units={objectForSave.units} />
      <Box sx={styles.buttonHolder}>
        <Button variant="contained" sx={styles.buttonSave} onClick={handleSave}>
          Save
        </Button>
        <Button
          variant="contained"
          sx={styles.buttonCansel}
          onClick={typeEdit === 'objectEdit' ? props.closeModal : handleCancel}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

function mapStateToProps(state) {
  const { currentObject } = selectLeadgen(state);
  return { currentObject };
}


export default connect(mapStateToProps)(ObjectEditor);
