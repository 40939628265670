import { Grid, Paper, Box, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import EntityInfoCard from "./EntityInfoCard";
import styles from "../../Leadgen.styles";
import EntityNotes from "./EntityNotes";
import Stakeholders from "./Stakeholders";
import { connect, useDispatch } from "react-redux";
import { receiveObjectsByOwnerRequest } from "../../actions/objects";
import { selectLeadgen } from "../../selectors";
import EntityRelatedInfo from "./EntityRelatedInfo";
import EntityObjects from "./EntityObjects";
import eventEmitter, {
  types,
} from "../../../../common/components/helpers/eventEmitter";
import Maps from "../common/Maps";

interface EntitiesBlockProps {
  entityObjectsList?: any;
  entityId?: string | null;
  owners?: any;
  objectsAmount?: any;
  addressForMap?: any;
}

const mapStyles = {
  height: "410px",
  marginTop: "1.5em",
};

function ColumnOne({ entityId, address }) {
  return (
    <React.Fragment>
      <Grid item xs={16} sx={styles.grid}>
        <Box sx={styles.columnItemsHolderBox}>
          <EntityInfoCard entityId={entityId} />
          <Paper sx={styles.firstColumnHolder}>
            <Typography
              variant="h4"
              component="div"
              sx={styles.contentTitleMain}
            >
              Stakeholders
            </Typography>
            <Stakeholders entityId={entityId} />
          </Paper>
        </Box>
      </Grid>
      <Grid item xs={16}>
        <Paper sx={styles.container}>
          <Typography variant="h4" component="div" sx={styles.contentTitleMain}>
            Maps
          </Typography>
          <Maps mapStyles={mapStyles} addressForMapDisplaying={address} />
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

function ColumnTwo({ entityId, objectsAmount, mainInfo, objectList }) {
  return (
    <React.Fragment>
      <Grid item xs={16} sx={styles.grid}>
        <Box sx={styles.columnItemsHolderBox}>
          <Paper sx={styles.secondColumnHolder}>
            <Typography
              variant="h4"
              component="div"
              sx={styles.contentTitleMain}
            >
              Notes
            </Typography>
            <EntityNotes entityId={entityId} />
          </Paper>
          <Paper sx={styles.secondColumnHolder}>
            <Typography
              variant="h4"
              component="div"
              sx={styles.contentTitleMain}
            >
              Related info
            </Typography>
            <EntityRelatedInfo entityId={entityId} />
          </Paper>
        </Box>
      </Grid>
      <Grid item xs={16} sx={styles.grid}>
        <Paper sx={styles.objectsContainer}>
          <Box sx={styles.objectsHolderBox}>
            <Typography
              variant="h4"
              component="div"
              sx={styles.objectContentTitle}
            >
              Objects ({objectsAmount})
            </Typography>
            <Typography variant="body2" component="div">
              Total size: {mainInfo.totalSize}m²
            </Typography>
            <Typography variant="body2" component="div">
              Housing: {mainInfo.houseSize}m²
            </Typography>
            <Typography variant="body2" component="div">
              Other: {mainInfo.otherSize}m²
            </Typography>
            <Typography variant="body2" component="div">
              Value: {mainInfo.totalPrice} €
            </Typography>
          </Box>
          <Box sx={styles.infoRow}>
            <Button
              variant="contained"
              sx={styles.buttonLinkObjects}
              onClick={() =>
                eventEmitter.emit(types.openObjectSearchModal, entityId)
              }
            >
              Link objects
            </Button>
            <Button
              variant="contained"
              sx={styles.buttonUnlinkObjects}
              onClick={() =>
                eventEmitter.emit(types.openAllObjectUnlinkModal, {
                  unLinkType: "all_objects",
                  entityId,
                  objectList,
                })
              }
            >
              Unlink all
            </Button>
          </Box>
          <EntityObjects entityId={entityId} />
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

const EntitiesBlock = (props: EntitiesBlockProps) => {
  const dispatch = useDispatch();
  const { entityId, entityObjectsList, objectsAmount, addressForMap } = props;
  const [entityObjects, setObjects] = useState([]);
  const [addressForMapDisplaying, setAddressForMapsDisplaying] = useState(null);
  const [mainInfo, setMainInfo] = useState({
    houseSize: "n/a",
    otherSize: "n/a",
    totalPrice: "n/a",
  } as any);

  const getObjectListHeadInfo = (entityObjectsList: any[]) => {
    let houseSize = 0;
    let otherSize = 0;
    let totalPrice = 0;

    entityObjectsList &&
      entityObjectsList.length > 0 &&
      entityObjectsList.forEach(({ house_size, other_size, price }) => {
        houseSize += house_size || 0;
        otherSize += other_size || 0;
        totalPrice += Number(price) || 0;
      });

    let totalSize = houseSize + otherSize;
    return { houseSize, otherSize, totalPrice, totalSize };
  };

  useEffect(() => {
    dispatch(receiveObjectsByOwnerRequest(entityId));
  }, [dispatch, entityId]);

  useEffect(() => {
    setObjects(entityObjectsList);
    setAddressForMapsDisplaying(addressForMap);
    const totalInfo = getObjectListHeadInfo(entityObjectsList);
    setMainInfo(totalInfo);
  }, [dispatch, entityObjectsList, addressForMap]);

  return (
    <Box sx={styles.holder}>
      <Grid container columns={16} spacing={3}>
        <Grid item xs={8}>
          <ColumnOne entityId={entityId} address={addressForMapDisplaying} />
        </Grid>
        <Grid item xs={8}>
          <ColumnTwo
            entityId={entityId}
            objectsAmount={objectsAmount}
            mainInfo={mainInfo}
            objectList={entityObjects}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

function mapStateToProps(state: any) {
  const { entityObjectsList, objectsAmount, addressForMap } =
    selectLeadgen(state);
  return { entityObjectsList, objectsAmount, addressForMap };
}

export default connect(mapStateToProps)(EntitiesBlock);
