import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "../EntityEditor.styles";
import StyledTextField from "../../styled-components/StyledTextField";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";

const initialEditModeState = {
  isEdit: false,
  index: -1,
};

const initialEmailState = {
  type: "",
  email: "",
};

const initialEmailDetails = {
  type: "",
  email: "",
  index: -1,
  id: "",
  modified: false,
};

const EntityEmailsBlock = ({ emails, onChange }) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(initialEditModeState);
  const [entityEmail, setEntityEmail] = useState(initialEmailState);
  const [entityEmailsList, setEntityEmailsList] = useState([]);
  const [editedEmailDetails, setEditedEmailDetails] =
    useState(initialEmailDetails);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emailForDelete, setEmailForDelete] = useState([]);

  useEffect(() => {
    setEntityEmailsList(emails);
  }, [dispatch, emails]);

  const handleClearForm = () => {
    /** clear all fields and error statuses **/
    setEditedEmailDetails(initialEmailDetails);
    setEntityEmail(initialEmailState);
    setEditMode(initialEditModeState);
    setEntityEmailsList([]);
    setEmailForDelete([]);
  };

  const handleChange = (
    name,
    key,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEntityEmail({
      ...entityEmail,
      [key]: event.target.value,
    });
  };

  const handleChangeItem = (fieldName, selectedIndex, event) => {
    setEditedEmailDetails({
      ...editedEmailDetails,
      [fieldName]: event.target.value,
      index: selectedIndex,
      modified: true,
    });
  };

  const handleDeleteItem = (selectedIndex) => {
    const newStateForDelete = [];
    entityEmailsList.map((item: any, index) => {
      if (index === selectedIndex && item.id) {
        // @ts-ignore
        return newStateForDelete.push(item);
      } else {
        return item;
      }
    });

    const filteredList = entityEmailsList.filter(
      (item: any, index) => index !== selectedIndex
    );

    const newState = {
      filteredList,
      newStateForDelete,
    };

    setEntityEmailsList(filteredList);
    setEmailForDelete(newStateForDelete);
    onChange("entityEmails", newState, true);
  };

  const handleSetEditMode = (type, selectedIndex) => {
    /** managing open/close state for ip item change, save changes by click in ip list **/
    if (type === "open") {
      setEditMode({ isEdit: !editMode.isEdit, index: selectedIndex });
      entityEmailsList.forEach((item: any, index) => {
        if (index === selectedIndex) {
          setEditedEmailDetails({
            ...item,
            index: selectedIndex,
            // id: item.id || null,
            type: item.type,
            email: item.email,
          });
        }
      });
    }
    if (type === "close") {
      setEditMode({ isEdit: !editMode.isEdit, index: selectedIndex });
    }
    if (type === "edit") {
      const newState = entityEmailsList.map((item: any, index) => {
        if (index === editedEmailDetails.index && index === selectedIndex) {
          return {
            // id: editedEmailDetails.id,
            type: editedEmailDetails.type,
            email: editedEmailDetails.email,
            modified: editedEmailDetails.modified,
          };
        } else {
          return item;
        }
      });
      // @ts-ignore
      setEntityEmailsList(newState);
      onChange("entityEmails", newState);
      setEditMode({ isEdit: !editMode.isEdit, index: selectedIndex });
      setEditedEmailDetails(editedEmailDetails);
    }
  };

  const addNewEmail = () => {
    if (entityEmail.email) {
      const newState = [
        ...entityEmailsList,
        {
          type: entityEmail.type,
          email: entityEmail.email,
        },
      ];
      // @ts-ignore
      setEntityEmailsList(newState);
      onChange("entityEmails", newState);
      handleClearForm();
    }
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography component="legend" sx={styles.textField}>
          Emails
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={styles.fieldHolder}>
          <Box sx={styles.fieldBlock}>
            <StyledTextField
              fullWidth
              label="Type"
              margin="dense"
              onChange={(e) => handleChange("entityEmails", "type", e)}
              value={entityEmail.type}
            />
          </Box>
          <Box sx={styles.fieldBlock}>
            <StyledTextField
              fullWidth
              required
              label="Email"
              margin="dense"
              onChange={(e) => handleChange("entityEmails", "email", e)}
              value={entityEmail.email}
            />
          </Box>
        </Box>
        <Button sx={styles.button} onClick={addNewEmail}>
          Add new email
        </Button>
        <Divider sx={{ marginTop: "15px", marginBottom: "15px" }} />
        {entityEmailsList?.length > 0 &&
          entityEmailsList.map(
            (
              item: {
                type: string;
                email: string;
              },
              index
            ) => {
              return editMode.isEdit && editMode.index === index ? (
                <Box sx={styles.editHolder} key={index}>
                  <Typography variant="body2" sx={styles.textField}>
                    Edit current email
                  </Typography>
                  <Box sx={styles.editFieldsBlockHolder}>
                    <Box sx={styles.fieldBlock}>
                      <TextField
                        sx={{ marginRight: "15px", width: "100%" }}
                        label="Type"
                        variant="standard"
                        margin="dense"
                        onChange={(e) => handleChangeItem("type", index, e)}
                        value={editedEmailDetails.type}
                      />
                    </Box>
                    <Box sx={styles.fieldBlock}>
                      <TextField
                        sx={{ width: "100%" }}
                        label="Email"
                        required
                        variant="standard"
                        margin="dense"
                        onChange={(e) => handleChangeItem("email", index, e)}
                        value={editedEmailDetails.email}
                      />
                    </Box>
                  </Box>
                  <Box sx={styles.editButtonGroup}>
                    <IconButton
                      onClick={() => handleSetEditMode("edit", index)}
                    >
                      <DoneIcon fontSize={"small"} />
                    </IconButton>
                    <IconButton
                      onClick={() => handleSetEditMode("close", index)}
                    >
                      <CloseIcon fontSize={"small"} />
                    </IconButton>
                  </Box>
                </Box>
              ) : (
                <Box sx={styles.itemContainer} key={index}>
                  <Box sx={styles.itemHolder}>
                    <Box sx={styles.infoHolder}>
                      <Box sx={styles.typeInfo}>
                        <Typography sx={styles.itemDetails} variant={"h6"}>
                          Type:
                        </Typography>
                        <Typography sx={styles.itemDetails} variant={"h6"}>
                          {item?.type}
                        </Typography>
                      </Box>
                      <Box sx={styles.info}>
                        <Typography sx={styles.itemDetails} variant={"h6"}>
                          Email:
                        </Typography>
                        <Typography sx={styles.itemDetails} variant={"h6"}>
                          {item?.email}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.iconHolder}>
                      <IconButton
                        aria-label="Edit"
                        onClick={() => handleSetEditMode("open", index)}
                      >
                        <EditIcon sx={styles.editIcon} />
                      </IconButton>
                      <IconButton
                        aria-label="Delete"
                        onClick={() => handleDeleteItem(index)}
                      >
                        <DeleteIcon sx={styles.deleteIcon} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              );
            }
          )}
      </AccordionDetails>
    </Accordion>
  );
};

export default EntityEmailsBlock;
