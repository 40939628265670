import React, { useEffect, useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Paper,
  Typography,
  TextField,
  MenuItem,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Tooltip,
  IconButton,
  CardActions,
  Button,
  Link,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { connect, useDispatch } from "react-redux";
import dayjs, { Dayjs } from "dayjs";

import { selectSuperAdmin } from "../super-admin/selectors";
import styles from "./Leads.styles";
import { receiveLabelsListRequest } from "../super-admin/actions";
import { receiveLeadsRequest } from "./actions";
import { selectAdmin } from "../admin/selectors";
import { adminGetUsersRequest } from "../admin/actions";
import { selectUser } from "../login/selectors";
import { selectLeads } from "./selectors";
import {
  getDifferenceInDays,
  getDateFromNow,
} from "../../common/formats/date-fromatter";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import eventEmitter, {
  types,
} from "../../common/components/helpers/eventEmitter";

const initialStateLabels = [
  {
    label: "All",
    value: "all",
  },
];

const initialStateUsers = [
  {
    label: "All",
    value: "all",
  },
];

const initialStatePeriod = {
  unscheduledLeads: [],
  todayLeads: [],
  overdueLeads: [],
  thisWeekLeads: [],
  nextWeekLeads: [],
  fromDateToDateLeads: [],
  curDay: new Date().getDay(),
};

const scheduleStatuses = [
  {
    value: "All",
  },
  {
    value: "Overdue",
  },
  {
    value: "Unscheduled",
  },
];

const periods = [
  {
    value: "Today",
  },
  {
    value: "This week",
  },
  {
    value: "Next week",
  },
  {
    value: "From date to date",
  },
];

const updatedList = (list: any) => {
  const updateList: any =
    list &&
    list.length &&
    list?.map((item: any) => {
      if (item.surname) {
        return { label: `${item.name} ${item.surname}`, value: item.id };
      } else {
        return { label: item.name, value: item.id };
      }
    });
  return updateList;
};

const Leads = (props: any) => {
  const diff = getDifferenceInDays;
  const dispatch = useDispatch();
  const { labelsList, users, userId, leadsList } = props;

  const [listLabels, setListLabels] = useState(initialStateLabels);
  const [selectedLabel, setSelectedLabel] = useState(
    initialStateLabels[0].value
  );

  const [currentLeadsList, setCurrentLeadsList] = useState(leadsList);
  const [filtredLeadsList, setFilteredLeadsList] = useState(leadsList);

  const [selectedScheduleStatuses, setSelectedScheduleStatuses] = useState(
    scheduleStatuses[0].value
  );
  const [selectedPeriod, setSelectedPeriod] = useState(periods[3].value);
  const [disabledDate, setDisabledDate] = useState(true);

  const [usersList, setUsersList] = useState(initialStateUsers);
  const [selectedUser, setSelectedUser] = useState("");

  const [selectingFilterByDate, setSelectingFilterByDate] =
    useState(initialStatePeriod);
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);

  useEffect(() => {
    dispatch(receiveLabelsListRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(adminGetUsersRequest());
  }, [dispatch]);

  useEffect(() => {
    const query = { responsible_person_type: "current" };
    dispatch(receiveLeadsRequest(query));
  }, [dispatch]);

  useEffect(() => {
    const query =
      selectedUser === "all"
        ? { responsible_person_type: "all" }
        : { responsible_person_type: "user", id: selectedUser };
    dispatch(receiveLeadsRequest(query));
  }, [dispatch, selectedUser]);

  useEffect(() => {
    setCurrentLeadsList(leadsList);
    setFilteredLeadsList(leadsList);
  }, [leadsList]);

  // ================== list labels and users ============== //

  useEffect(() => {
    const newList = updatedList(labelsList);
    if (!!newList) {
      setListLabels(
        [...listLabels, ...newList].filter(
          (obj, idx, arr) => idx === arr.findIndex((t) => t.value === obj.value)
        )
      );
    }
  }, [dispatch, labelsList]);

  useEffect(() => {
    const newList = updatedList(users);
    if (!!newList) {
      setUsersList(
        [...usersList, ...newList].filter(
          (obj, idx, arr) => idx === arr.findIndex((t) => t.value === obj.value)
        )
      );
    }
  }, [dispatch, users]);

  useEffect(() => {
    if (!!userId) {
      setSelectedUser(userId);
    }
  }, [userId]);

  // ==============================================//

  useEffect(() => {
    if (selectedPeriod === periods[3].value) {
      setDisabledDate(false);
    } else {
      setFromDate(null);
      setToDate(null);
      setDisabledDate(true);
    }
  }, [selectedPeriod]);

  useEffect(() => {
    setFilteredLeadsList(filteredScheduleStatuses);
  }, [
    selectedLabel,
    selectedScheduleStatuses,
    selectedPeriod,
    fromDate,
    toDate,
    currentLeadsList,
  ]);

  // ================= filter unscheduled ================== //

  const filterUnscheduledLeads = (leads: any) => {
    let newLeads = [];
    if (leads) {
      newLeads = leads.filter((lead: any) => !lead.entity.schedule);
    }
    return newLeads;
  };

  // ================= filter today ================== //

  const filterTodayLeads = (leads: any) => {
    let newLeads = [];
    if (leads) {
      newLeads = leads.filter((lead: any) => diff(lead.entity.schedule) === 0);
    }
    return newLeads;
  };

  // ================= filter overdue ================== //

  const filterOverdueLeads = (leads: any) => {
    let newLeads = [];
    if (leads) {
      newLeads = leads.filter((lead: any) => {
        return lead.entity.schedule !== null && diff(lead.entity.schedule) < 0;
      });
    }
    return newLeads;
  };

  // ================= filter this week ================== //

  const filterThisWeekLeads = (leads: any) => {
    let newLeads = [];
    if (leads) {
      newLeads = leads.filter(
        (lead: any) =>
          diff(lead.entity.schedule) >= 0 &&
          diff(lead.entity.schedule) <= 7 - selectingFilterByDate.curDay
      );
    }
    return newLeads;
  };

  // ================= filter next week================== //

  const filterNextWeekLeads = (leads: any) => {
    let newLeads = [];
    if (leads) {
      newLeads = leads.filter(
        (lead: any) =>
          diff(lead.entity.schedule) >= 7 - selectingFilterByDate.curDay &&
          diff(lead.entity.schedule) <= 14 - selectingFilterByDate.curDay
      );
    }
    return newLeads;
  };

  // ================= filter from date to date ================== //

  const filterFromDateToDateLeads = (leads: any) => {
    let newLeads = [];
    if (leads && fromDate && toDate) {
      newLeads = leads.filter(
        (lead: any) =>
          dayjs(fromDate) < dayjs(lead.entity.schedule) &&
          dayjs(toDate) > dayjs(lead.entity.schedule)
      );
    }
    return newLeads;
  };

  // ================= filter labels ================== //

  const filteredLeadsLabel = () => {
    if (
      selectedLabel &&
      selectedLabel !== initialStateLabels[0].value &&
      currentLeadsList.length
    ) {
      return currentLeadsList.filter(
        ({ entity }) =>
          !!entity.label[0] && entity.label[0].id === selectedLabel
      );
    } else {
      return currentLeadsList;
    }
  };

  const filteredScheduleStatuses = () => {
    let newLeads = [];
    let filteredNewLeads = [];

    if (
      selectedLabel === initialStateLabels[0].value &&
      selectedPeriod === periods[3].value &&
      !toDate &&
      !fromDate
    ) {
      if (selectedScheduleStatuses === scheduleStatuses[1].value) {
        return filterOverdueLeads(currentLeadsList);
      }
      if (selectedScheduleStatuses === scheduleStatuses[2].value) {
        return filterUnscheduledLeads(currentLeadsList);
      }
      if (selectedScheduleStatuses === scheduleStatuses[0].value) {
        return currentLeadsList;
      }
    }

    if (
      selectedLabel !== initialStateLabels[0].value &&
      selectedPeriod === periods[3].value &&
      !toDate &&
      !fromDate
    ) {
      newLeads = filteredLeadsLabel();
      if (selectedScheduleStatuses === scheduleStatuses[1].value) {
        return filterOverdueLeads(newLeads);
      }
      if (selectedScheduleStatuses === scheduleStatuses[2].value) {
        return filterUnscheduledLeads(newLeads);
      }
      if (selectedScheduleStatuses === scheduleStatuses[0].value) {
        return newLeads;
      }
    }

    if (
      selectedLabel === initialStateLabels[0].value &&
      (selectedPeriod !== periods[3].value ||
        (selectedPeriod === periods[3].value && fromDate && toDate))
    ) {
      if (selectedScheduleStatuses === scheduleStatuses[0].value) {
        switch (selectedPeriod) {
          case periods[0].value: {
            return filterTodayLeads(currentLeadsList);
          }

          case periods[1].value: {
            return filterThisWeekLeads(currentLeadsList);
          }

          case periods[2].value: {
            return filterNextWeekLeads(currentLeadsList);
          }

          case periods[3].value: {
            return filterFromDateToDateLeads(currentLeadsList);
          }

          default: {
            return currentLeadsList;
          }
        }
      } else {
        switch (selectedPeriod) {
          case periods[0].value: {
            if (selectedScheduleStatuses === scheduleStatuses[1].value) {
              filteredNewLeads = filterOverdueLeads(currentLeadsList);
            }
            if (selectedScheduleStatuses === scheduleStatuses[2].value) {
              filteredNewLeads = filterUnscheduledLeads(currentLeadsList);
            }
            return filterTodayLeads(filteredNewLeads);
          }

          case periods[1].value: {
            if (selectedScheduleStatuses === scheduleStatuses[1].value) {
              filteredNewLeads = filterOverdueLeads(currentLeadsList);
            }
            if (selectedScheduleStatuses === scheduleStatuses[2].value) {
              filteredNewLeads = filterUnscheduledLeads(currentLeadsList);
            }

            return filterThisWeekLeads(filteredNewLeads);
          }

          case periods[2].value: {
            if (selectedScheduleStatuses === scheduleStatuses[1].value) {
              filteredNewLeads = filterOverdueLeads(currentLeadsList);
            }
            if (selectedScheduleStatuses === scheduleStatuses[2].value) {
              filteredNewLeads = filterUnscheduledLeads(currentLeadsList);
            }

            return filterNextWeekLeads(filteredNewLeads);
          }

          case periods[3].value: {
            if (selectedScheduleStatuses === scheduleStatuses[1].value) {
              filteredNewLeads = filterOverdueLeads(currentLeadsList);
            }
            if (selectedScheduleStatuses === scheduleStatuses[2].value) {
              filteredNewLeads = filterUnscheduledLeads(currentLeadsList);
            }

            return filterFromDateToDateLeads(filteredNewLeads);
          }

          default: {
            return currentLeadsList;
          }
        }
      }
    }

    if (
      selectedLabel !== initialStateLabels[0].value &&
      (selectedPeriod !== periods[3].value ||
        (selectedPeriod === periods[3].value && fromDate && toDate))
    ) {
      newLeads = filteredLeadsLabel();

      if (
        selectedPeriod === periods[0].value &&
        selectedScheduleStatuses === scheduleStatuses[0].value
      ) {
        return filterTodayLeads(newLeads);
      }
      if (
        selectedPeriod === periods[0].value &&
        selectedScheduleStatuses === scheduleStatuses[1].value
      ) {
        filteredNewLeads = filterOverdueLeads(newLeads);
        return filterTodayLeads(filteredNewLeads);
      }
      if (
        selectedPeriod === periods[0].value &&
        selectedScheduleStatuses === scheduleStatuses[2].value
      ) {
        filteredNewLeads = filterUnscheduledLeads(newLeads);
        return filterTodayLeads(filteredNewLeads);
      }

      if (
        selectedPeriod === periods[1].value &&
        selectedScheduleStatuses === scheduleStatuses[0].value
      ) {
        return filterThisWeekLeads(newLeads);
      }
      if (
        selectedPeriod === periods[1].value &&
        selectedScheduleStatuses === scheduleStatuses[1].value
      ) {
        filteredNewLeads = filterOverdueLeads(newLeads);
        return filterThisWeekLeads(filteredNewLeads);
      }
      if (
        selectedPeriod === periods[1].value &&
        selectedScheduleStatuses === scheduleStatuses[2].value
      ) {
        filteredNewLeads = filterUnscheduledLeads(newLeads);
        return filterThisWeekLeads(filteredNewLeads);
      }

      if (
        selectedPeriod === periods[2].value &&
        selectedScheduleStatuses === scheduleStatuses[0].value
      ) {
        return filterNextWeekLeads(newLeads);
      }
      if (
        selectedPeriod === periods[2].value &&
        selectedScheduleStatuses === scheduleStatuses[1].value
      ) {
        filteredNewLeads = filterOverdueLeads(newLeads);
        return filterNextWeekLeads(filteredNewLeads);
      }
      if (
        selectedPeriod === periods[2].value &&
        selectedScheduleStatuses === scheduleStatuses[2].value
      ) {
        filteredNewLeads = filterUnscheduledLeads(newLeads);
        return filterNextWeekLeads(filteredNewLeads);
      }

      if (
        selectedPeriod === periods[3].value &&
        selectedScheduleStatuses === scheduleStatuses[0].value
      ) {
        return filterFromDateToDateLeads(newLeads);
      }
      if (
        selectedPeriod === periods[3].value &&
        selectedScheduleStatuses === scheduleStatuses[1].value
      ) {
        filteredNewLeads = filterUnscheduledLeads(newLeads);
        return filterFromDateToDateLeads(filteredNewLeads);
      }
      if (
        selectedPeriod === periods[3].value &&
        selectedScheduleStatuses === scheduleStatuses[2].value
      ) {
        filteredNewLeads = filterUnscheduledLeads(newLeads);
        return filterFromDateToDateLeads(filteredNewLeads);
      }
    }
  };

  // ================= filter statistic ================== //

  useEffect(() => {
    const unscheduledLeads = filterUnscheduledLeads(currentLeadsList);
    const todayLeads = filterTodayLeads(currentLeadsList);
    const overdueLeads = filterOverdueLeads(currentLeadsList);
    const thisWeekLeads = filterThisWeekLeads(currentLeadsList);
    const nextWeekLeads = filterNextWeekLeads(currentLeadsList);
    const fromDateToDateLeads = filterFromDateToDateLeads(currentLeadsList);

    setSelectingFilterByDate({
      ...selectingFilterByDate,
      unscheduledLeads,
      todayLeads,
      overdueLeads,
      thisWeekLeads,
      nextWeekLeads,
      fromDateToDateLeads,
    });
  }, [leadsList, fromDate, toDate, currentLeadsList]);

  const handleChangeLabel = (event: any) => {
    const label = event.target.value;
    setSelectedLabel(label);
  };

  const handleChangeScheduleStatuses = (event: any) => {
    const status = event.target.value;
    setSelectedScheduleStatuses(status);
  };

  const handleChangeUsers = (event: any) => {
    const user = event.target.value;
    setSelectedUser(user);
  };

  const handleChangePeriod = (event: any) => {
    const period = event.target.value;
    setSelectedPeriod(period);
  };

  return (
    <>
      <Box>
        <AppBar position="relative" sx={styles.dataBox}>
          <Toolbar sx={styles.toolBar}>
            <Box sx={styles.boxContent}>
              <Box sx={styles.boxContent}>
                <Typography
                  component="div"
                  sx={[styles.headerContent, styles.textSelect]}
                >
                  Label:
                </Typography>
                <TextField
                  id="outlined-select-currency"
                  sx={[styles.headerContent, styles.textSelect]}
                  size="small"
                  select
                  value={selectedLabel}
                  onChange={handleChangeLabel}
                  focused
                >
                  {listLabels.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography component="div" sx={styles.headerContent}>
                  SCHEDULE STATUS:
                </Typography>
                <TextField
                  id="outlined-select-currency"
                  sx={[styles.headerContent, styles.textSelect]}
                  size="small"
                  value={selectedScheduleStatuses}
                  select
                  onChange={(event) => handleChangeScheduleStatuses(event)}
                  focused
                >
                  {scheduleStatuses.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography component="div" sx={styles.headerContent}>
                  PERIOD:
                </Typography>
                <TextField
                  id="outlined-select-currency"
                  sx={[styles.headerContent, styles.textSelect]}
                  size="small"
                  select
                  value={selectedPeriod}
                  onChange={(event) => handleChangePeriod(event)}
                  focused
                >
                  {periods.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography component="div" sx={styles.headerContent}>
                SHOW LEADS FOR:
              </Typography>
              <TextField
                id="outlined-select-currency"
                sx={[styles.headerContent, styles.textSelect]}
                size="small"
                value={selectedUser}
                onChange={handleChangeUsers}
                select
                focused
              >
                {usersList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value !== userId ? option.label : "Me"}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Toolbar>
        </AppBar>
        <Paper sx={styles.formHolder}>
          <Box sx={styles.menuPanel}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid sx={styles.dateContainer} container spacing={2}>
                <Grid item xs={6}>
                  <DatePicker
                    sx={styles.inputDate}
                    disabled={disabledDate}
                    label="From"
                    value={fromDate}
                    onChange={(newValue) => setFromDate(dayjs(newValue))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    sx={styles.inputDate}
                    disabled={disabledDate}
                    label="To"
                    value={toDate}
                    onChange={(newValue) => setToDate(dayjs(newValue))}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>

            <Box>
              <Grid
                container
                columnSpacing={4}
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <Grid item xs={3}>
                  <Typography
                    sx={[styles.headerContent, styles.statisticContent]}
                    style={{ textAlign: "right" }}
                  >
                    STATISTIC:
                  </Typography>
                </Grid>
                <Grid item container xs={9} spacing={1}>
                  <Grid
                    container
                    item
                    spacing={4}
                    direction="row"
                    justifyContent="flex-end"
                  >
                    <Grid item xs={4}>
                      <Typography
                        sx={[styles.headerContent, styles.statisticContent]}
                      >
                        Total leads amount: {leadsList.length}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        sx={[styles.headerContent, styles.statisticContent]}
                      >
                        Overdue leads:{" "}
                        {selectingFilterByDate.overdueLeads.length}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        sx={[styles.headerContent, styles.statisticContent]}
                      >
                        Leads this week:{" "}
                        {selectingFilterByDate.thisWeekLeads.length}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    spacing={4}
                    direction="row"
                    justifyContent="flex-end"
                  >
                    <Grid item xs={4}>
                      <Typography
                        sx={[styles.headerContent, styles.statisticContent]}
                      >
                        Unscheduled leads:{" "}
                        {selectingFilterByDate.unscheduledLeads.length}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        sx={[styles.headerContent, styles.statisticContent]}
                      >
                        Leads today: {selectingFilterByDate.todayLeads.length}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        sx={[styles.headerContent, styles.statisticContent]}
                      >
                        Leads next week:{" "}
                        {selectingFilterByDate.nextWeekLeads.length}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ padding: "3rem" }}>
            <Grid
              container
              rowSpacing={{ xs: 2 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {filtredLeadsList &&
                filtredLeadsList.map((card: any, index: any) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Card
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <CardHeader
                        sx={styles.cardHeader}
                        avatar={
                          <Tooltip
                            title={`Responsible person for this user: ${card.entity.responsible_person.name}`}
                          >
                            <Avatar
                              sx={{ bgcolor: "#D9D9D9" }}
                              aria-label="responsible__person"
                              src={
                                !!card.entity.responsible_person.img_url
                                  ? card.entity.responsible_person.img_url
                                  : ""
                              }
                            >
                              <PersonIcon sx={{ color: "#495057" }} />
                            </Avatar>
                          </Tooltip>
                        }
                        action={
                          <Box>
                            <IconButton
                              onClick={() =>
                                eventEmitter.emit(
                                  types.openScheduleCalendarModal,
                                  `${card.entity.id}`
                                )
                              }
                              aria-label="settings"
                              sx={{
                                padding: 0,
                              }}
                            >
                              <EditCalendarIcon />
                            </IconButton>
                          </Box>
                        }
                        title={
                          <Link
                            sx={styles.link}
                            href={`/leadgen/entity/${card.entity.id}`}
                          >
                            {`${card.entity.name}${
                              card.entity.age ? `, ${card.entity.age} y.o.` : ""
                            }`}
                          </Link>
                        }
                        subheader={
                          card.entity.schedule
                            ? getDateFromNow(card.entity.schedule).label
                            : "no schedule date"
                        }
                      />

                      <CardContent>
                        <Typography sx={styles.cardContentTypography}>
                          m² in own: {card.objects.common_size}
                        </Typography>
                        <Typography sx={styles.cardContentTypography}>
                          Objects amount: {card.objects.object_count}
                        </Typography>
                        <Typography sx={styles.cardContentTypography}>
                          Units amount: {card.objects.units_count}
                        </Typography>
                        <Typography sx={styles.cardContentTypography}>
                          Total price, €: {card.objects.common_price}
                        </Typography>
                      </CardContent>
                      <CardActions
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          sx={{
                            fontSize: "16px",
                            color: "#495057",
                            fontWeight: 500,
                          }}
                          onClick={() =>
                            eventEmitter.emit(
                              types.openLeadNoteModal,
                              `${card.entity.id}`
                            )
                          }
                        >
                          SHOW NOTES
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

function mapStateToProps(state: any) {
  const { leadsList } = selectLeads(state);
  const { labelsList } = selectSuperAdmin(state);
  const { users } = selectAdmin(state);
  const { userId } = selectUser(state);
  return { labelsList, users, userId, leadsList };
}

export default connect(mapStateToProps)(Leads);
