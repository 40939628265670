import { useEffect, useState } from "react";
import {
  APIProvider,
  Map,
  Marker,
  useMapsLibrary,
  useMarkerRef,
} from "@vis.gl/react-google-maps";
import { connect } from "react-redux";
import { selectLeadgen } from "../../selectors";

const { REACT_APP_API_KEY } = process.env;

interface MapsProps {
  mapStyles: object;
  currentObject?: object;
  entityObjectsList?: any;
  addressForMapDisplaying?: any;
}

const defaultCenterAmsterdam = {
  lat: 52.3679843,
  lng: 4.903561399999944,
};

const getObjectAddress = (currentObject: any) => {
  return `${currentObject.city} ${currentObject.objectAddress}`;
};

const getEntityObjectsAddresses = (entityObjectsList: any) => {
  return entityObjectsList.map((object: any) => {
    return `${object.city} ${object.objectAddress}`;
  });
};

function Geocoding({ address, addresses, mapStyles }) {
  const geocodingApiLoaded = useMapsLibrary("geocoding");
  const [geocodingService, setGeocodingService] =
    useState<google.maps.Geocoder>();
  const [geocodingResult, setGeocodingResult] =
    useState<google.maps.GeocoderResult>();
  const [markerRef, marker] = useMarkerRef();

  const [geocodingLat, setGeocodingLat] = useState(52.3679843);
  const [geocodingLng, setGeocodingLng] = useState(4.903561399999944);

  useEffect(() => {
    if (geocodingResult) {
      setGeocodingLat(geocodingResult.geometry.location.lat());
      setGeocodingLng(geocodingResult.geometry.location.lng());
    }
  }, [geocodingResult]);

  useEffect(() => {
    if (!geocodingApiLoaded) return;
    if (!marker) return;

    setGeocodingService(new window.google.maps.Geocoder());
  }, [geocodingApiLoaded, marker]);

  useEffect(() => {
    if (!geocodingService) return;

    if (addresses) {
      geocodingService.geocode({ address: addresses[0] }, (results, status) => {
        if (results && status === "OK") {
          setGeocodingResult(results[0]);
          setGeocodingLat(results[0].geometry.location.lat());
          setGeocodingLng(results[0].geometry.location.lng());
        }
      });
    }
    if (address) {
      geocodingService.geocode({ address }, (results, status) => {
        if (results && status === "OK") {
          setGeocodingResult(results[0]);
          setGeocodingLat(results[0].geometry.location.lat());
          setGeocodingLng(results[0].geometry.location.lng());
        }
      });
    }
  }, [geocodingService, address, addresses]);

  return (
    <Map
      style={mapStyles}
      defaultZoom={20}
      gestureHandling={"greedy"}
      // @ts-ignore
      center={
        { lat: geocodingLat, lng: geocodingLng } || defaultCenterAmsterdam
      }
      // options={{ streetViewControl: true }}
    >
      <Marker
        ref={markerRef}
        position={{ lat: geocodingLat, lng: geocodingLng }}
      />
    </Map>
  );
}

const Maps = (props: MapsProps) => {
  const {
    mapStyles,
    currentObject,
    entityObjectsList,
    addressForMapDisplaying,
  } = props;
  const [address, setAddress] = useState("");
  const [addresses, setAddresses] = useState("");
  useEffect(() => {
    if (currentObject) {
      setAddress(getObjectAddress(currentObject));
    }
    if (entityObjectsList.length > 0) {
      setAddresses(getEntityObjectsAddresses(entityObjectsList));
    }
    if (addressForMapDisplaying) {
      setAddress(addressForMapDisplaying);
    }
  }, [currentObject, entityObjectsList, addressForMapDisplaying]);

  return (
    <APIProvider
      //@ts-ignore
      apiKey={REACT_APP_API_KEY}
    >
      <Geocoding
        address={address}
        addresses={addresses}
        mapStyles={mapStyles}
      />
    </APIProvider>
  );
};

function mapStateToProps(state: any) {
  const { entityObjectsList, currentObject } = selectLeadgen(state);
  return { entityObjectsList, currentObject };
}

export default connect(mapStateToProps)(Maps);
