import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "../EntityEditor.styles";
import StyledTextField from "../../styled-components/StyledTextField";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { ChangeEvent, useEffect, useState } from "react";
import PostcodeForm from "../../postcode-form/PostcodeForm";
import { useDispatch } from "react-redux";

const initialEditModeState = {
  isEdit: false,
  index: -1,
};
const initialAddressState = {
  type: "",
  postcode: "",
  city: "",
  street: "",
  number: "",
  number_add: "",
  municipality: "",
  city_code: "",
};

const initialAddressDetails = {
  type: "",
  postcode: "",
  city: "",
  street: "",
  number: "",
  number_add: "",
  municipality: "",
  city_code: "",
  index: -1,
  id: "",
  modified: false,
};

const EntityAddressBlock = ({ entityAddresses, onChange, typeEdit }) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(initialEditModeState);
  /**current address which we type into fields**/
  const [entityAddress, setEntityAddress] = useState(initialAddressState);
  /**address list from props which we save in state**/
  const [entityAddressList, setEntityAddressList] = useState([]);
  /**details for update directly in fields**/
  const [editedAddressDetails, setEditedAddressDetails] = useState(
    initialAddressDetails
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addressForDelete, setAddressForDelete] = useState([]);

  useEffect(() => {
    setEntityAddressList(entityAddresses);
  }, [dispatch, entityAddresses]);

  console.log('entityAddressList', entityAddressList);

  const handleClearForm = () => {
    /** clear all fields and error statuses **/
    setEditedAddressDetails(initialAddressDetails);
    setEntityAddress(initialAddressState);
    setEditMode(initialEditModeState);
    setAddressForDelete([]);
    setEntityAddressList([]);
  };

  const handleChange = (
    name,
    key,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEntityAddress({
      ...entityAddress,
      [key]: event.target.value,
    });
  };

  const handleChangeItem = (fieldName, selectedIndex, event) => {
    setEditedAddressDetails({
      ...editedAddressDetails,
      [fieldName]: event.target.value,
      index: selectedIndex,
      modified: true,
    });
  };

  const handleDeleteItem = (selectedIndex) => {
    const newStateForDelete = [];
    entityAddressList.map((item: any, index) => {
      if (index === selectedIndex && item.id) {
        // @ts-ignore
        return newStateForDelete.push(item);
      } else {
        return item;
      }
    });

    const filteredList = entityAddressList.filter(
      (item: any, index) => index !== selectedIndex
    );

    const newState = {
      filteredList,
      newStateForDelete,
    };

    setEntityAddressList(filteredList);
    setAddressForDelete(newStateForDelete);
    onChange("entityAddresses", newState, true);
  };

  const handleSetEditMode = (type, selectedIndex) => {
    /** managing open/close state for ip item change, save changes by click in ip list **/
    if (type === "open") {
      setEditMode({ isEdit: !editMode.isEdit, index: selectedIndex });
      entityAddressList.forEach((item: any, index) => {
        if (index === selectedIndex) {
          setEditedAddressDetails({
            ...item,
            index: selectedIndex,
            // id: item.id || null,
            type: item.type,
            postcode: item.postcode,
            city: item.city,
            street: item.street,
            number: item.number,
            number_add: item.number_add,
            municipality: item.municipality,
            city_code: item.city_code,
          });
        }
      });
    }
    if (type === "close") {
      setEditMode({ isEdit: !editMode.isEdit, index: selectedIndex });
    }
    if (type === "edit") {
      const newState = entityAddressList.map((item: any, index) => {
        if (index === editedAddressDetails.index && index === selectedIndex) {
          return {
            // id: editedAddressDetails.id,
            type: editedAddressDetails.type,
            postcode: editedAddressDetails.postcode,
            city: editedAddressDetails.city,
            street: editedAddressDetails.street,
            number: editedAddressDetails.number,
            number_add: editedAddressDetails.number_add,
            municipality: editedAddressDetails.municipality,
            city_code: editedAddressDetails.city_code,
            modified: editedAddressDetails.modified,
          };
        } else {
          return item;
        }
      });
      // @ts-ignore
      setEntityAddressList(newState);
      onChange("entityAddresses", newState);
      setEditMode({ isEdit: !editMode.isEdit, index: selectedIndex });
      setEditedAddressDetails(editedAddressDetails);
    }
  };

  const addNewAddress = () => {
    if (entityAddress.city) {
      const newState = [
        ...entityAddressList,
        {
          type: entityAddress.type,
          postcode: entityAddress.postcode,
          city: entityAddress.city,
          street: entityAddress.street,
          number: entityAddress.number,
          number_add: entityAddress.number_add,
          // municipality: entityAddress.municipality,
          // city_code: entityAddress.city_code
        },
      ];
      // @ts-ignore
      setEntityAddressList(newState);
      onChange("entityAddresses", newState);
      handleClearForm();
    }
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography component="legend" sx={styles.textField}>
          Addresses
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <PostcodeForm />
        <Box sx={styles.fieldHolder}>
          <Box sx={styles.fieldBlock}>
            <StyledTextField
              fullWidth
              label="Type"
              margin="dense"
              onChange={(e) => handleChange("entityAddresses", "type", e)}
              value={entityAddress.type}
            />
            <StyledTextField
              fullWidth
              required
              label="City"
              margin="dense"
              onChange={(e) => handleChange("entityAddresses", "city", e)}
              value={entityAddress.city}
            />
            <StyledTextField
              fullWidth
              label="Number"
              margin="dense"
              onChange={(e) => handleChange("entityAddresses", "number", e)}
              value={entityAddress.number}
            />
          </Box>
          <Box sx={styles.fieldBlock}>
            <StyledTextField
              fullWidth
              label="Postcode"
              margin="dense"
              onChange={(e) => handleChange("entityAddresses", "postcode", e)}
              value={entityAddress.postcode}
            />
            <StyledTextField
              fullWidth
              label="Street"
              margin="dense"
              onChange={(e) => handleChange("entityAddresses", "street", e)}
              value={entityAddress.street}
            />
            <StyledTextField
              fullWidth
              label="Number add"
              margin="dense"
              onChange={(e) => handleChange("entityAddresses", "number_add", e)}
              value={entityAddress.number_add}
            />
          </Box>
        </Box>
        <Button sx={styles.button} onClick={addNewAddress}>
          Add new address
        </Button>
        <Divider sx={{ marginTop: "15px", marginBottom: "15px" }} />
        {entityAddressList?.length > 0 &&
          entityAddressList.map(
            (
              item: {
                type: string;
                postcode: string;
                city: string;
                street: string;
                number: string;
                number_add: string;
              },
              index
            ) => {
              return editMode.isEdit && editMode.index === index ? (
                <Box sx={styles.editHolder} key={index}>
                  <Typography variant="body2" sx={styles.textField}>
                    Edit current address
                  </Typography>
                  <Box sx={styles.editFieldsBlockHolder}>
                    <Box sx={styles.fieldBlock}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Type"
                        margin="dense"
                        onChange={(e) => handleChangeItem("type", index, e)}
                        value={editedAddressDetails.type}
                      />
                      <TextField
                        fullWidth
                        required
                        variant="standard"
                        label="City"
                        margin="dense"
                        onChange={(e) => handleChangeItem("city", index, e)}
                        value={editedAddressDetails.city}
                      />
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Number"
                        margin="dense"
                        onChange={(e) => handleChangeItem("number", index, e)}
                        value={editedAddressDetails.number}
                      />
                    </Box>
                    <Box sx={styles.fieldBlock}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Postcode"
                        margin="dense"
                        onChange={(e) => handleChangeItem("postcode", index, e)}
                        value={editedAddressDetails.postcode}
                      />
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Street"
                        margin="dense"
                        onChange={(e) => handleChangeItem("street", index, e)}
                        value={editedAddressDetails.street}
                      />
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Number add"
                        margin="dense"
                        onChange={(e) =>
                          handleChangeItem("number_add", index, e)
                        }
                        value={editedAddressDetails.number_add}
                      />
                    </Box>
                  </Box>
                  <Box sx={styles.editButtonGroup}>
                    <IconButton
                      onClick={() => handleSetEditMode("edit", index)}
                    >
                      <DoneIcon fontSize={"small"} />
                    </IconButton>
                    <IconButton
                      onClick={() => handleSetEditMode("close", index)}
                    >
                      <CloseIcon fontSize={"small"} />
                    </IconButton>
                  </Box>
                </Box>
              ) : (
                <Box sx={styles.itemContainer} key={index}>
                  <Box sx={styles.itemHolder}>
                    <Box sx={styles.infoHolder}>
                      <Box sx={styles.typeInfo}>
                        <Typography sx={styles.itemDetails} variant={"h6"}>
                          Type:
                        </Typography>
                        <Typography sx={styles.itemDetails} variant={"h6"}>
                          {item.type}
                        </Typography>
                      </Box>
                      <Box sx={styles.info}>
                        <Typography sx={styles.itemDetails} variant={"h6"}>
                          Address:
                        </Typography>
                        <Typography sx={styles.itemDetails} variant={"h6"}>
                          {`${item.postcode} ${item.city}, ${item.street} ${item.number} ${item.number_add}`}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.iconHolder}>
                      <IconButton
                        aria-label="Edit"
                        onClick={() => handleSetEditMode("open", index)}
                      >
                        <EditIcon sx={styles.editIcon} />
                      </IconButton>
                      <IconButton
                        aria-label="Delete"
                        onClick={() => handleDeleteItem(index)}
                      >
                        <DeleteIcon sx={styles.deleteIcon} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              );
            }
          )}
      </AccordionDetails>
    </Accordion>
  );
};

export default EntityAddressBlock;
