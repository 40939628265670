import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "../EntityEditor.styles";
import StyledTextField from "../../styled-components/StyledTextField";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";

const initialEditModeState = {
  isEdit: false,
  index: -1,
};

const initialSocialState = {
  type: "",
  social: "",
};

const socialLinkOptions = [
  {
    label: "Facebook",
    value: "facebook",
  },
  {
    label: "Twitter",
    value: "twitter",
  },
  {
    label: "LinkedIn",
    value: "linkedin",
  },
];

const initialSocialDetails = {
  type: "",
  social: "",
  index: -1,
  id: "",
  modified: false,
};

const fbLink = "https://www.facebook.com/";
const twLink = "https://twitter.com/";
const lnLink = "https://www.linkedin.com/";

const EntitySocialBlock = ({ socials, onChange }) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(initialEditModeState);
  const [entitySocial, setEntitySocial] = useState(initialSocialState);
  const [entitySocialsList, setEntitySocialsList] = useState([]);
  const [editedSocialDetails, setEditedSocialDetails] =
    useState(initialSocialDetails);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [socialForDelete, setSocialForDelete] = useState([]);

  useEffect(() => {
    setEntitySocialsList(socials);
  }, [dispatch, socials]);

  const handleClearForm = () => {
    /** clear all fields and error statuses **/
    setEditedSocialDetails(initialSocialDetails);
    setEntitySocial(initialSocialState);
    setEditMode(initialEditModeState);
    setEntitySocialsList([]);
    setSocialForDelete([]);
  };

  const handleChange = (
    name,
    key,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (key === "type" && event.target.value === "facebook") {
      return setEntitySocial({
        ...entitySocial,
        [key]: event.target.value,
        social: fbLink,
      });
    }
    if (key === "type" && event.target.value === "twitter") {
      return setEntitySocial({
        ...entitySocial,
        [key]: event.target.value,
        social: twLink,
      });
    }
    if (key === "type" && event.target.value === "linkedin") {
      return setEntitySocial({
        ...entitySocial,
        [key]: event.target.value,
        social: lnLink,
      });
    }

    return setEntitySocial({
      ...entitySocial,
      [key]: event.target.value,
    });
  };

  const handleChangeItem = (fieldName, selectedIndex, event) => {
    setEditedSocialDetails({
      ...editedSocialDetails,
      [fieldName]: event.target.value,
      index: selectedIndex,
      modified: true,
    });
  };

  const handleDeleteItem = (selectedIndex) => {
    const newStateForDelete = [];
    entitySocialsList.map((item: any, index) => {
      if (index === selectedIndex && item.id) {
        // @ts-ignore
        return newStateForDelete.push(item);
      } else {
        return item;
      }
    });

    const filteredList = entitySocialsList.filter(
      (item: any, index) => index !== selectedIndex
    );

    const newState = {
      filteredList,
      newStateForDelete,
    };

    setEntitySocialsList(filteredList);
    setSocialForDelete(newStateForDelete);
    onChange("entitySocials", newState, true);
  };

  const handleSetEditMode = (type, selectedIndex) => {
    /** managing open/close state for ip item change, save changes by click in ip list **/
    if (type === "open") {
      setEditMode({ isEdit: !editMode.isEdit, index: selectedIndex });
      entitySocialsList.forEach((item: any, index) => {
        if (index === selectedIndex) {
          setEditedSocialDetails({
            ...item,
            index: selectedIndex,
            // id: item.id || null,
            type: item.type,
            social: item.social,
          });
        }
      });
    }
    if (type === "close") {
      setEditMode({ isEdit: !editMode.isEdit, index: selectedIndex });
    }
    if (type === "edit") {
      const newState = entitySocialsList.map((item: any, index) => {
        if (index === editedSocialDetails.index && index === selectedIndex) {
          return {
            // id: editedSocialDetails.id,
            type: editedSocialDetails.type,
            social: editedSocialDetails.social,
            modified: editedSocialDetails.modified,
          };
        } else {
          return item;
        }
      });
      // @ts-ignore
      setEntitySocialsList(newState);
      onChange("entitySocials", newState);
      setEditMode({ isEdit: !editMode.isEdit, index: selectedIndex });
      setEditedSocialDetails(editedSocialDetails);
    }
  };

  const addNewSocial = () => {
    if (entitySocial.social) {
      const newState = [
        ...entitySocialsList,
        {
          type: entitySocial.type,
          social: entitySocial.social,
        },
      ];
      // @ts-ignore
      setEntitySocialsList(newState);
      onChange("entitySocials", newState);
      handleClearForm();
    }
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography component="legend" sx={styles.textField}>
          Social media
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={styles.fieldHolder}>
          <Box sx={styles.fieldBlock}>
            <StyledTextField
              fullWidth
              select
              label="Type"
              margin="dense"
              onChange={(e) => handleChange("entitySocials", "type", e)}
              value={entitySocial.type}
            >
              {socialLinkOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </StyledTextField>
          </Box>
          <Box sx={styles.fieldBlock}>
            <StyledTextField
              fullWidth
              required
              label="Link"
              margin="dense"
              onChange={(e) => handleChange("entitySocials", "social", e)}
              value={entitySocial.social}
            />
          </Box>
        </Box>
        <Button sx={styles.button} onClick={addNewSocial}>
          Add new social .net
        </Button>
        <Divider sx={{ marginTop: "15px", marginBottom: "15px" }} />
        {entitySocialsList?.length > 0 &&
          entitySocialsList.map(
            (
              item: {
                type: string;
                social: string;
              },
              index
            ) => {
              return editMode.isEdit && editMode.index === index ? (
                <Box sx={styles.editHolder} key={index}>
                  <Typography variant="body2" sx={styles.textField}>
                    Edit current social link
                  </Typography>
                  <Box sx={styles.editFieldsBlockHolder}>
                    <Box sx={styles.fieldBlock}>
                      <TextField
                        sx={{ marginRight: "15px", width: "100%" }}
                        label="Type"
                        variant="standard"
                        onChange={(e) => handleChangeItem("type", index, e)}
                        value={editedSocialDetails.type}
                      />
                    </Box>
                    <Box sx={styles.fieldBlock}>
                      <TextField
                        sx={{ width: "100%" }}
                        label="Link"
                        variant="standard"
                        required
                        onChange={(e) => handleChangeItem("social", index, e)}
                        value={editedSocialDetails.social}
                      />
                    </Box>
                  </Box>
                  <Box sx={styles.editButtonGroup}>
                    <IconButton
                      onClick={() => handleSetEditMode("edit", index)}
                    >
                      <DoneIcon fontSize={"small"} />
                    </IconButton>
                    <IconButton
                      onClick={() => handleSetEditMode("close", index)}
                    >
                      <CloseIcon fontSize={"small"} />
                    </IconButton>
                  </Box>
                </Box>
              ) : (
                <Box sx={styles.itemContainer} key={index}>
                  <Box sx={styles.itemHolder}>
                    <Box sx={styles.infoHolder}>
                      <Box sx={styles.typeInfo}>
                        <Typography sx={styles.itemDetails} variant={"h6"}>
                          Type:
                        </Typography>
                        <Typography sx={styles.itemDetails} variant={"h6"}>
                          {item?.type}
                        </Typography>
                      </Box>
                      <Box sx={styles.info}>
                        <Typography sx={styles.itemDetails} variant={"h6"}>
                          Social link:
                        </Typography>
                        <Typography sx={styles.itemDetails} variant={"h6"}>
                          {item?.social}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.iconHolder}>
                      <IconButton
                        aria-label="Edit"
                        onClick={() => handleSetEditMode("open", index)}
                      >
                        <EditIcon sx={styles.editIcon} />
                      </IconButton>
                      <IconButton
                        aria-label="Delete"
                        onClick={() => handleDeleteItem(index)}
                      >
                        <DeleteIcon sx={styles.deleteIcon} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              );
            }
          )}
      </AccordionDetails>
    </Accordion>
  );
};

export default EntitySocialBlock;
