import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import styles from "../../Leadgen.styles";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  receiveObjectByIdRequest,
  setObjectDelete,
} from "../../actions/objects";
import { selectLeadgen } from "../../selectors";
import eventEmitter, {
  types,
} from "../../../../common/components/helpers/eventEmitter";

interface ObjectProps {
  objectId?: string | undefined;
  currentObject?: any;
  isDeleteObject: boolean;
}

const Object = (props: ObjectProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const [objectDetails, setObjectDetails] = useState({} as any);
  const { objectId, currentObject, isDeleteObject } = props;

  useEffect(() => {
    dispatch(receiveObjectByIdRequest(objectId));
  }, [dispatch, objectId]);

  useEffect(() => {
    currentObject && setObjectDetails(currentObject);
  }, [dispatch, currentObject]);

  useEffect(() => {
    if (isDeleteObject) {
      navigate("/leadgen");
      dispatch(setObjectDelete());
    }
  }, [dispatch, navigate, isDeleteObject]);

  const getUnitAddress = (address: any) => {
    const streetName = address.street_name ? address.street_name : "";
    const number = address.number ? address.number : "";
    const numberAdd = address.number_add ? address.number_add : "";
    return `${streetName} ${number} ${numberAdd}`;
  };

  const setRowOpen = () => {
    setOpen(!isOpen);
  };

  return (
    <Card sx={styles.card}>
      <Box
        sx={styles.objectInfoHolder}
      >
        <Typography variant="h4" component="div" sx={styles.objectContentTitle}>
          {`${objectDetails.city}, ${objectDetails.objectAddress}`}
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Button
            variant="contained"
            sx={styles.buttonLinkObjects}
            // onClick={() =>
            //   eventEmitter.emit(types.openObjectSearchModal, entityId)
            // }
            onClick={() =>
              eventEmitter.emit(
                types.openEntityObjectEditModal,
                "objectEdit",
                objectId
              )
            }
          >
            EDIT
          </Button>
          <Button
            variant="contained"
            sx={styles.buttonUnlinkObjects}
            // onClick={() =>
            //   eventEmitter.emit(types.openAllObjectUnlinkModal, { unLinkType: 'all_objects', entityId, objectList })
            // }
            onClick={() =>
              eventEmitter.emit(
                types.openDeleteEntityObjectModal,
                "objectDelete",
                objectId
              )
            }
          >
            DELETE
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "17px",
          marginBottom: "15px",
          width: "100%",
        }}
      >
        <Typography variant="body2" component="div">
          Total size: {objectDetails.full_size}m²
        </Typography>
        <Typography variant="body2" component="div">
          Housing: {objectDetails.house_size}m²
        </Typography>
        <Typography variant="body2" component="div">
          Other: {objectDetails.other_size}m²
        </Typography>
        <Typography variant="body2" component="div">
          Value: {objectDetails.price} €
        </Typography>
      </Box>
      <TableContainer component={Paper} sx={styles.objectTable}>
        <Table aria-label="collapsible table">
          <TableHead sx={{ background: "#B4CEE5" }}>
            <TableRow>
              <TableCell />
              <TableCell>Address</TableCell>
              <TableCell align="center">Size, m²</TableCell>
              <TableCell align="center">Purpose</TableCell>
              <TableCell align="center">Value, €</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ padding: "16px 6px" }}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setRowOpen()}
                >
                  {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row">
                {objectDetails.objectAddress}
              </TableCell>
              <TableCell align="center">
                {objectDetails.full_size || "n/a"}
              </TableCell>
              <TableCell align="center">
                {objectDetails.purpose || "n/a"}
              </TableCell>
              <TableCell align="center">
                {objectDetails.price || "n/a"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={10}
              >
                {isOpen && objectDetails.units.length > 0 && (
                  <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: "10px 10px 20px" }}>
                      <Table size="small" aria-label="units_details">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Unit address</TableCell>
                            <TableCell align="center">Unit size, m²</TableCell>
                            <TableCell align="center">Purpose</TableCell>
                            <TableCell align="center">Value, €</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {objectDetails.units.map((unit, index) => (
                            <TableRow key={index}>
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {getUnitAddress(unit.address)}
                              </TableCell>
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {unit.size || "n/a"}
                              </TableCell>
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {unit.purpose || "n/a"}
                              </TableCell>
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {unit.price || "n/a"}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

function mapStateToProps(state) {
  const { currentObject, isDeleteObject } = selectLeadgen(state);
  return { currentObject, isDeleteObject };
}

export default connect(mapStateToProps)(Object);
