import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  AppBar,
  Paper,
  Box,
  Toolbar,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import styles from "./Admin.styles";
import { selectAdmin } from "./selectors";
import { adminGetUsersRequest } from "./actions";
import eventEmitter, {
  types,
} from "../../common/components/helpers/eventEmitter";

function createData(
  id: number,
  email: string,
  name: string,
  surname: string,
  role: string,
  lastActivity: string,
  activityDetails: string,
  whitelisted: string,
  actions: string
) {
  return {
    id,
    email,
    name,
    surname,
    role,
    lastActivity,
    activityDetails,
    whitelisted,
    actions,
  };
}

const Admin = (props: any) => {
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const { users } = props;

  useEffect(() => {
    dispatch(adminGetUsersRequest());
  }, [dispatch]);

  useEffect(() => {
    setUserList(users);
  }, [users]);

  const rows = userList.map((user: any) => {
    return createData(
      user?.id,
      user?.email,
      user?.name,
      user?.surname,
      user?.role,
      user?.last_activity_date,
      "SHOW DETAILS",
      user?.ip_restriction,
      ""
    );
  });

  const columns = [
    { id: "email", label: "Email" },
    { id: "name", label: "Name" },
    { id: "surname", label: "Surname" },
    { id: "role", label: "Role" },
    { id: "lastActivity", label: "Last activity" },
    { id: "activityDetails", label: "Activity details" },
    { id: "whitelisted", label: "Whitelisted" },
    { id: "actions", label: "Actions" },
  ];

  return (
    <>
      <Box>
        <AppBar position="relative" sx={styles.dataBox}>
          <Toolbar sx={styles.toolBar}>
            <Typography component="div" sx={styles.headerContent}>
              Admin panel Randvast
            </Typography>
            <Button
              color="inherit"
              sx={styles.buttonContent}
              onClick={() => eventEmitter.emit(types.openCreateUpdateUserModal)}
            >
              + CREATE USER
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <TableContainer component={Paper} sx={styles.table}>
        <Table sx={{ minWidth: 650 }} size="medium" stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column: any) => (
                <TableCell key={column.id} sx={styles.dataHeaderTable}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {columns.map((column) => {
                  const value = row[column.id];
                  return column.id === "activityDetails" ? (
                    <TableCell
                      align="left"
                      key={column.id}
                      sx={styles.dataTableBody}
                    >
                      <Button
                        sx={styles.buttonTableBody}
                        onClick={() =>
                          eventEmitter.emit(types.openUserLogsModal, row.id)
                        }
                      >
                        {value}
                      </Button>
                    </TableCell>
                  ) : column.id === "actions" ? (
                    <TableCell
                      align="left"
                      key={column.id}
                      sx={styles.dataTableBody}
                    >
                      <IconButton
                        aria-label="EditButton"
                        onClick={() =>
                          eventEmitter.emit(
                            types.openCreateUpdateUserModal,
                            row.id
                          )
                        }
                      >
                        <EditIcon
                          sx={{
                            color: "#B4CEE5",
                            fontSize: {
                              xs: "medium",
                              sm: "medium",
                              md: "24px",
                            },
                          }}
                        />
                      </IconButton>
                      <IconButton
                        aria-label="DeleteButton"
                        onClick={() =>
                          eventEmitter.emit(types.openDeleteUserModal, row.id)
                        }
                      >
                        <DeleteIcon
                          sx={{
                            color: "#B4CEE5",
                            fontSize: {
                              xs: "medium",
                              sm: "medium",
                              md: "24px",
                            },
                          }}
                        />
                      </IconButton>
                    </TableCell>
                  ) : (
                    <TableCell
                      align="left"
                      key={column.id}
                      sx={styles.dataTableBody}
                    >
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

function mapStateToProps(state: any) {
  const users = selectAdmin(state);
  return users;
}

export default connect(mapStateToProps)(Admin);
