const styles = {
  list: {
    maxHeight: 380,
    marginBottom: "15px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
  emptyList: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Poppins",
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  dependentEntityListItem: {
    // paddingTop: 0,
    // paddingBottom: 0,
  },
  buttonContent: {
    fontFamily: "typography.fontFamily",
    fontStyle: "normal",
    fontWeight: 400,
    width: "100%",
    marginBottom: "20px",
    backgroundColor: "#B4CEE5",
    fontSize: {
      xs: "12px",
      md: "14px",
      lg: "16px",
    },
    color: "#114B76CC",
    "&:hover": {
      backgroundColor: "rgba(180, 206, 229, 0.5)",
    },
  },
  deleteIcon: {
    color: "#B4CEE5",
  },
  dataApp: {
    minWidth: "27rem",
    marginBottom: "2rem",
  },
  dataAppForDeleteModal: {
    margin: "48px",
  },
  contentContainer: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    color: "#495057",
    textAlign: "center",
  },
  formHolder: {
    overflow: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
  contentNote: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontSize: 16,
  },
  contentAuthor: {
    fontWeight: 600,
  },
  contentBody: {
    minWidth: "25rem",
    minHeight: "10rem",
    maxHeight: "28rem",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
  containerWithoutNotes: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "25rem",
    minHeight: "10rem",
  },
  scheduleSubTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#495057",
    fontSize: "18px",
    marginBottom: "20px",
  },
  columnHolder: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonHolder: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "330px",
    marginBottom: "20px",
  },
  buttonContentCalendar: {
    fontFamily: "typography.fontFamily",
    fontStyle: "normal",
    fontWeight: 400,
    width: "103px",
    marginBottom: "5px",
    backgroundColor: "#B4CEE5",
    fontSize: {
      xs: "12px",
      md: "14px",
      lg: "16px",
    },
    color: "#114B76CC",
    "&:hover": {
      backgroundColor: "rgba(180, 206, 229, 0.5)",
    },
  },
  buttonContentClear: {
    fontFamily: "typography.fontFamily",
    fontStyle: "normal",
    fontWeight: 400,
    width: "100%",
    marginBottom: "20px",
    // backgroundColor: "#D67575",
    backgroundColor: "#B4CEE5",
    fontSize: {
      xs: "12px",
      md: "14px",
      lg: "16px",
    },
    color: "#114B76CC",
    "&:hover": {
      backgroundColor: "rgba(180, 206, 229, 0.5)",
    },
  },
  dateCalendar: {
    height: "300px",
  },
  dataAppUserLogsModal: {
    flexGrow: 1,
    height: "55px",
    background: "rgba(17,75,118,0.9)",
    padding: 0,
    borderRadius: "5px 5px 0px 0px",
    boxShadow: "none",
    marginBottom: "12px",
  },
  headerContent: {
    flexGrow: 1,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    color: "#FFF",
  },
  contentCard: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#74788D",
  },
  headerTable: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    color: "#495057",
    paddingBottom: "8px",
  },
  contentTable: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#74788D",
  },
  cardContainer: {
    flex: "1 0 auto",
    maxHeight: 380,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },
  textFieldPadding: {
    color: "#495057",
  },
  textRole: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    color: "#495057",
    justifyContent: "center",
  },
  buttonIp: {
    display: "flex",
    width: "93%",
    margin: "0 auto 10px",
    color: "rgb(17,75,118)",
    "&:hover": {
      backgroundColor: "rgba(17,75,118,0.1)",
    },
  },
  ipHolder: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 2px 0 8px",
  },
  editIpHolderFields: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  editIpHolderButtons: {
    padding: "17px 5px 5px 5px",
    display: "flex",
    justifyContent: "space-between",
  },
  ipTitle: {
    color: "#495057",
    marginRight: "45px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "2.6",
  },
  formHolderUserModal: {
    maxHeight: "735px",
    overflow: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  },

  contentBodyEdit: {
    minWidth: "40rem",
    minHeight: "10rem",
    maxHeight: "70vh",
    overflow: "hidden",
    overflowY: "auto",
    marginBottom:"2rem",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "4px",
    },
  }
};

export default styles;
